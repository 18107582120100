import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useBookSearch from '../components/useBookSearch';
import { Link } from 'react-router-dom';
import styles from '../styles/pages/Books.module.css';

const Books = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [query, setQuery] = useState('');
  const { t, i18n } = useTranslation();

  const {
    books,
    hasMore,
    loading,
    error
  } = useBookSearch(query, pageNumber)

  const observer = useRef()
  const lastBookElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  const handleSearchInputChange = (e) => {
    setQuery(e.target.value);
    setPageNumber(1);
  }

  const bookCard = (book) => {
    return <>
      {/* Book cover */}
      <img 
        src={book.coverUrl} 
        alt={book.title} 
        className={`w-24 h-32 object-cover ${i18n.language === "ar" ? 'ml-4' : 'mr-4'}`}
      />

      <div>
        <h2 className="text-xl font-semibold mb-2 hover:underline transition duration-200">
          <Link
            to={`/books/${book.id}`}
            className="text-dark-green hover:text-medium-green"
          >
            {book.title}
          </Link>
        </h2>

        {/* Short description (3 lines max) */}
        <p className={`${styles.description} mb-2`}>
          {book.description.length > 100 ? (
            <>
              {book.description.slice(0, 100)}...
              <Link to={`/books/${book.id}`} className="text-medium-green font-light text-sm">
                {t('books.readMore')}
              </Link>
            </>
          ) : book.description}
        </p>


        <p className="mt-2"><strong>{t('books.author')}:</strong> {book.author}</p>
        <p><strong>{t('books.publisher')}:</strong> {book.publisher}</p>
      </div>
    </>
  };

  return (
    <div className={styles.booksPage}>
      <h1 className="text-center text-2xl font-bold mb-6">{t('books.title')}</h1>

      {/* Filter input */}
      <div className="mb-6">
        <input
          type="text"
          value={query}
          onChange={handleSearchInputChange}
          placeholder={t('books.filterPlaceholder')}
          className="p-2 border rounded w-full"
        />
      </div>

      {loading && <div className="text-center text-gray-500 mt-5">{t('books.loading')}</div>}
      {error && <div className="text-center text-gray-500 mt-5">{error}</div>}
      {!loading && !books.length && <div className="text-center text-gray-500 mt-5">{t('books.noResults')}</div>}

      {/* Books grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {books.map((book, index) => (
          // Book card
          books.length === index + 1 ? (
            <div key={book.id} ref={lastBookElementRef} className={`${styles.bookCard} p-4 border rounded shadow-lg flex`}>
              {bookCard(book)}
            </div>
          ) : (
            <div key={book.id} className={`${styles.bookCard} p-4 border rounded shadow-lg flex`}>
              {bookCard(book)}
            </div>
          )
        ))}
      </div>


      {/* Spinner for loading more books */}
      {loading && (
        <div className="text-center my-5">
          <div className="spinner">
              <div></div>
              <div></div>
              <div></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Books;
