import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FatwaDetail from './pages/FatwaDetail';
import FatwaEncyclopedia from './pages/FatwaEncyclopedia';
import Home from './pages/Home';
import SubmitQuestion from './pages/SubmitQuestion';
import Navbar from './components/Navbar';
import About from './pages/About';
import Donate from './pages/Donate';
import VideoCallBooking from './pages/VideoCallBooking';
import Books from './pages/Books';
import BookDetails from './pages/BookDetails';
import FatwaChat from './components/FatwaChat';
import './styles/main.css';
import { useTranslation } from 'react-i18next';


function App() {
  const { i18n } = useTranslation();
  return (
    <Router basename="/app">
      <div className={`App ${i18n.language === 'ar' ? 'rtl-container text-right' : 'ltr-container text-left'}`}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/submit-question" element={<SubmitQuestion />} />
          <Route path="/encyclopedia" element={<FatwaEncyclopedia />} />
          <Route path="/fatwa/:refNo" element={<FatwaDetail />} />
          <Route path="/video-call-booking" element={<VideoCallBooking />} />
          <Route path="/books" element={<Books />} />
          <Route path="/books/:id" element={<BookDetails />} />
        </Routes>
        <FatwaChat />
      </div>
    </Router>
  );
}

export default App;
