import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as BookCallIcon } from '../assets/icons/book-video-call.svg';
import { ReactComponent as AskQuestionIcon } from '../assets/icons/question.svg';
import styles from '../styles/components/FatwaActionsSlider.module.css';

const FatwaActionsSlider = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const navigate = useNavigate();
  
  // Slider settings
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,   // Always show one item
    slidesToScroll: 1,  // Scroll one item at a time
    autoplay: true,     // Enable automatic transitions
    autoplaySpeed: 3000, // Time in milliseconds between transitions (e.g., 3000ms = 3 seconds)
    responsive: [
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: 1,  // Show 1 item
          slidesToScroll: 1,
          dots: true,
        }
      },
      {
        breakpoint: 1024, // For tablet and above
        settings: {
          slidesToShow: 1,  // Show 1 item
          slidesToScroll: 1,
        }
      }
    ]
  };  

  return (
    <section className={`${styles.sliderContainer} py-10 text-center text-white`}>
      <Slider {...settings}>
        {/* Book Call Button */}
        <div>
          <button
            className={styles.buttonContainer}
            onClick={() => navigate('/video-call-booking')}
          >
            <span className="flex justify-center items-center" style={{ direction: isArabic ? "rtl" : "ltr" }}>
              <BookCallIcon className={`w-5 h-5 ${isArabic ? "ml-2" : "mr-2"}`} /> 
              {t('home.fatwaActions.bookCall')}
            </span>
          </button>
        </div>

        {/* Ask Question Button */}
        <div>
          <button
            className={styles.buttonContainer}
            onClick={() => navigate('/submit-question')}
          >
            <span className="flex justify-center items-center" style={{ direction: isArabic ? "rtl" : "ltr" }}>
              <AskQuestionIcon className={`w-5 h-5 ${isArabic ? "ml-2" : "mr-2"}`} /> 
              {t('home.fatwaActions.askQuestion')}
            </span>
          </button>
        </div>
      </Slider>
    </section>
  );
}

export default FatwaActionsSlider;
