import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobileDevice from '../components/useIsMobileDevice';
import { createChat, sendMessageToChannel, getChatHistory } from '../services/apiService';
import Cookies from 'js-cookie';
import styles from '../styles/components/FatwaChat.module.css';
import { ReactComponent as RestartIcon } from '../assets/icons/restart.svg';
import { ReactComponent as RetryIcon } from '../assets/icons/retry.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';

const FatwaChat = () => {
    const { t, i18n } = useTranslation();
    const [isChatVisible, setIsChatVisible] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const [currentChatMessage, setCurrentChatMessage] = useState('');
    const [chatChannelId, setChatChannelId] = useState(null);
    const [pseudo, setPseudo] = useState('');
    const [pseudoInput, setPseudoInput] = useState('');
    const isMobileDevice = useIsMobileDevice();
    const [error, setError] = useState(null);
    const [isRetrying, setIsRetrying] = useState(false);
    const isArabic = i18n.language === 'ar';

    const toggleChatVisibility = () => {
        setIsChatVisible(!isChatVisible);
    };

    const handleChatInputChange = (event) => {
        setCurrentChatMessage(event.target.value);
    };

    const fetchChatHistory = useCallback(async (channelId) => {
        try {
            const response = await getChatHistory(channelId);
            if (response && response.success) {
                const formattedMessages = response.messages
                    .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort by date
                    .map(msg => ({
                        sender: msg.author === 'Public user' ? 'user' : 'bot',
                        text: msg.body.replace(/<\/?[^>]+(>|$)/g, ""), // Remove HTML tags
                    }));

                setChatMessages(formattedMessages);
            } else {
                setError(t('chat.error_fetching_history'));
            }
        } catch (error) {
            setError(t('chat.error_fetching_history'));
        }
    }, [t]);

    // Check for existing chat channel and poll for history
    useEffect(() => {
        const chatChannelIdFromCookies = Cookies.get('chatChannelId');
        if (chatChannelIdFromCookies && chatChannelIdFromCookies !== 'null') {
            setChatChannelId(chatChannelIdFromCookies);
        }

        const chatUserPseudoFromCookies = Cookies.get('chatUserPseudo');
        if (chatUserPseudoFromCookies) {
            setPseudo(chatUserPseudoFromCookies);
        }

        if (chatChannelId) {
            fetchChatHistory(chatChannelId);
            const interval = setInterval(() => {
                fetchChatHistory(chatChannelId);
            }, 10000); // Poll every 10 seconds

            return () => clearInterval(interval); // Cleanup on component unmount
        }
    }, [chatChannelId, fetchChatHistory]);

    const handlePseudoInputKeyyUp = async (event) => {
        if (event.key === 'Enter') {
            const trimmedPseudo = pseudoInput.trim();
            if (!trimmedPseudo) {
                setError(t('chat.pseudo_required'));
                return;
            }
            Cookies.set('chatUserPseudo', trimmedPseudo);
            setPseudo(trimmedPseudo);
        }
    }

    const handleChatInputKeyUp = async (event) => {
        if (event.key === 'Enter') {
            const trimmedMessage = currentChatMessage.trim();
            if (!trimmedMessage || !pseudo) {
                setError(t('chat.pseudo_required'));
                return;
            }

            setChatMessages(prevMessages => [
                ...prevMessages,
                { sender: 'user', text: trimmedMessage }
            ]);

            setCurrentChatMessage('');

            try {
                if (!chatChannelId) {
                    const chatResponse = await createChat(trimmedMessage, pseudo);
                    if (chatResponse && chatResponse.success) {
                        Cookies.set('chatChannelId', chatResponse.channel_id);
                        setChatChannelId(chatResponse.channel_id);
                    } else {
                        setError(t('chat.error_creating_channel'));
                    }
                } else {
                    const sendResponse = await sendMessageToChannel(chatChannelId, trimmedMessage, pseudo);
                    if (!sendResponse || !sendResponse.success) {
                        setError(t('chat.error_sending_message'));
                    }
                }
            } catch (error) {
                setError(t('chat.error_creating_message'));
            }
        }
    };

    const handleRestartChat = () => {
        Cookies.set('chatChannelId', null);
        setChatMessages([]);
        setChatChannelId(null);
        // Cookies.set('chatUserPseudo', null);
    };

    const handleResendMessage = async (message) => {
        setIsRetrying(true); // Start the spinning animation
        try {
            let activeChannelId = chatChannelId;
            if (!activeChannelId) {
                const chatResponse = await createChat(message, pseudo);
                if (chatResponse && chatResponse.success) {
                    Cookies.set('chatChannelId', chatResponse.channel_id);
                    setChatChannelId(chatResponse.channel_id);
                    activeChannelId = chatResponse.channel_id;
                } else {
                    setError(t('chat.error_creating_channel'));
                    setIsRetrying(false);
                    return;
                }
            }

            const sendResponse = await sendMessageToChannel(activeChannelId, message);
            if (!sendResponse || !sendResponse.success) {
                setError(t('chat.error_sending_message'));
            } else {
                setError(null);
            }
        } catch (error) {
            setError(t('chat.error_creating_message'));
        } finally {
            setIsRetrying(false); // Stop the spinning animation
        }
    };

    return (
        <div className={styles.chatWidgetContainer}>
            <div
                className={`${styles.chatBubble} w-16 h-16 bg-white rounded-full flex items-center justify-center cursor-pointer text-3xl shadow-lg`}
                onClick={toggleChatVisibility}
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 text-lime-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                </svg>
            </div>
            {isChatVisible && (
                <div
                    className={`${styles.chatPopup} absolute bottom-20 right-0 ${isMobileDevice ? 'w-[90vw]' : 'w-96'} rounded-md shadow-md flex flex-col transition-all text-sm`}
                >
                    <div className="flex justify-between items-center p-4 bg-gradient-green-r text-white rounded-t-md">
                        <RestartIcon
                            className="w-5 h-5 hover:cursor-pointer"
                            onClick={handleRestartChat}
                            title={t('chat.restartConversation')}
                        />
                        <h3 className="m-0 text-lg">
                            {t('chat.widget_header')}
                            {pseudo && (
                                <>
                                {":  "}<span style={{ fontSize: '1rem', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)', color: '#F8FAFC', fontStyle: 'italic' }}>{pseudo}</span>
                                </>
                            )}
                            </h3>

                        <CloseIcon
                            className="w-5 h-5 hover:cursor-pointer"
                            onClick={toggleChatVisibility}
                            title={t('chat.closeWidget')}
                        />
                    </div>

                    {/* Pseudo input */}
                    {!pseudo && (
                        <div className="p-4">
                            <input
                                type="text"
                                className="w-full border border-red-500 rounded-md px-4 py-2 outline-none bg-zinc-100 text-black"
                                placeholder={t('chat.enterPseudo')}
                                value={pseudoInput}
                                onChange={(e) => setPseudoInput(e.target.value)}
                                onKeyUp={handlePseudoInputKeyyUp}
                            />
                        </div>
                    )}

                    <div className="flex-1 p-4 overflow-y-auto">
                        {chatMessages.map((message, index) => (
                            <div key={index} className={`flex mb-3 ${message.sender === 'user' ? (isArabic ? 'justify-start' : 'justify-end') : (isArabic ? 'justify-end' : 'justify-start')}`}>
                                <div className={`rounded-lg py-2 px-4 max-w-[70%] ${message.sender === 'user' ? 'bg-gradient-green-r text-white' : 'bg-zinc-200 text-black'}`}>
                                    {message.text}
                                </div>
                                {index === chatMessages.length - 1 && error && (
                                    <RetryIcon
                                        className={`w-4 h-4 hover:cursor-pointer items-center self-center ${isRetrying ? 'animate-spin' : ''} ${isArabic ? 'mr-2' : 'ml-2'}`}
                                        onClick={() => handleResendMessage(message.text)}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                    {error && (
                        <div className="my-1 p-2 bg-red-100 border border-red-400 text-red-700 text-center shadow-sm">
                            {error}
                        </div>
                    )}

                    <div className="p-4 border-t border-gray-200">
                        <div className="flex items-center">
                            <input
                                type="text"
                                className={`${styles.chatInput} flex-1 border border-gray-300 rounded-md px-4 py-2 outline-none bg-zinc-200 text-black`}
                                value={currentChatMessage}
                                onChange={handleChatInputChange}
                                onKeyUp={handleChatInputKeyUp}
                                placeholder={t('chat.writeYourQuestion')}
                                disabled={!pseudo} // Disable input if no pseudo is provided
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FatwaChat;
