import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getBookById } from '../services/apiService';
import styles from '../styles/pages/BookDetails.module.css';

const BookDetails = () => {
  const { id } = useParams();
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchBook = async () => {
      try {
        const data = await getBookById(id);
        setBook(data);
      } catch (error) {
        setError(t('bookDetails.errorFetchingBook'));
      } finally {
        setLoading(false);
      }
    };

    fetchBook();
  }, [id, t]);

  if (loading) {
    return <div className="text-center text-gray-500 mt-5">{t('bookDetails.loading')}</div>;
  }

  if (error) {
    return <div className="text-center text-gray-500 mt-5">{error}</div>;
  }

  return (
    <div className={`${styles.bookDetailsContainer} max-w-4xl mx-auto p-6`}>
      <div className="flex flex-col md:flex-row">
        {/* Book Cover */}
        <div className="md:w-1/3">
          <img
            src={book.coverUrl}
            alt={book.title}
            className="w-full h-auto rounded-lg shadow-md"
          />
        </div>
        {/* Book Information */}
        <div className={`md:w-2/3 ${i18n.language === "ar" ? "md:pr-8" : "md:pl-8"} mt-6 md:mt-0`}>
          <h1 className="text-3xl font-bold text-gray-800 mb-4">{book.title}</h1>
          <p className="text-lg text-gray-600 mb-6 italic">{book.description}</p>
          
          {/* Book details */}
          <div className="space-y-4">
            <p>
              <strong className="font-semibold">{t('bookDetails.author')}:</strong>{' '}
              <span className="text-gray-700">{book.author}</span>
            </p>
            <p>
              <strong className="font-semibold">{t('bookDetails.publisher')}:</strong>{' '}
              <span className="text-gray-700">{book.publisher}</span>
            </p>
            <p>
                <strong className="font-semibold"><span className={`${i18n.language === "ar" ? styles.fixAlign : ''}`}>{t('bookDetails.isbn')}</span>:</strong>{' '}
                <span className="text-gray-700">{book.isbn}</span>
            </p>
            <p>
              <strong className="font-semibold">{t('bookDetails.publicationDate')}:</strong>{' '}
              <span className="text-gray-700">{book.publicationDate}</span>
            </p>
            <p>
              <strong className="font-semibold">{t('bookDetails.pages')}:</strong>{' '}
              <span className="text-gray-700">{book.pages}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookDetails;
