import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ReturnBackwardIcon } from '../assets/icons/return-arrow.svg';
import styles from '../styles/pages/BookingConfirmation.module.css';

const BookingConfirmation = ({ bookingDetails }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const handleBackToHome = () => {
        navigate('/');
    };

    return (
        <div className={`${styles.bookingConfirmationPage} p-6 bg-gray-50 min-h-screen`}>
            <h2 className="text-2xl font-semibold mb-6 text-center">{t('bookingConfirmation.title')}</h2>
            <div className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md">
                <p className="text-lg mb-4">
                    {t('bookingConfirmation.thankYou', { muftiName: bookingDetails.mufti.name })}
                </p>
                <p className="font-semibold mb-2">{t('bookingConfirmation.bookingDetails')}</p>
                <ul className="list-disc list-inside mb-4">
                    <li><strong>{t('bookingConfirmation.firstName')}:</strong> {bookingDetails.firstName}</li>
                    <li><strong>{t('bookingConfirmation.lastName')}:</strong> {bookingDetails.lastName}</li>
                    <li><strong>{t('bookingConfirmation.email')}:</strong> {bookingDetails.email}</li>
                    <li><strong>{t('bookingConfirmation.phone')}:</strong> {bookingDetails.phone}</li>
                    <li><strong>{t('bookingConfirmation.callSubject')}:</strong> {bookingDetails.callSubject}</li>
                </ul>
                <p className="text-md mb-4">
                    {t('bookingConfirmation.callBookingReviewMessage')}
                </p>
                <p className="text-md mb-4">
                    {t('bookingConfirmation.emailConfirmationMessage')}
                </p>
                <button
                    onClick={handleBackToHome}
                    className="w-full bg-gradient-green-r text-white py-2 px-4 rounded hover:bg-blue-600 transition"
                >
                    <span className="flex justify-center items-start">
                        <ReturnBackwardIcon className={`w-5 h-5 ${i18n.language === "ar" ? "ml-2" : "mr-2"}`} /> 
                        {t('bookingConfirmation.backToHome')}
                    </span>
                </button>
            </div>
        </div>
    );
};

export default BookingConfirmation;
