import i18n from 'i18next';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const phoneRegex = /^\+?[0-9\s-]{7,}$/; // Allows optional '+' at the beginning and requires at least 7 digits

export const validateSubmitQuestion = (formData) => {
    if (!formData.firstName || !formData.lastName || !formData.phone || !formData.title || !formData.question) {
        return i18n.t('submitQuestion.allFieldsRequired');
    }

    // Validate phone number format
    if (!phoneRegex.test(formData.phone)) {
        return i18n.t('submitQuestion.invalidPhone');
    }
    
    return null;
};

export const validateBookingCall = (formData) => {
    if (!formData.email || !formData.firstName || !formData.lastName || !formData.phone || !formData.callSubject) {
        return i18n.t('bookingForm.allFieldsRequired');
    }

    // Validate email format
    if (!emailRegex.test(formData.email)) {
        return i18n.t('bookingForm.invalidEmail');
    }

    // Validate phone number format
    if (!phoneRegex.test(formData.phone)) {
        return i18n.t('bookingForm.invalidPhone');
    }

    return null;
};
