import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { validateBookingCall } from '../utils/validation';
import { bookCall } from '../services/apiService';
import { ReactComponent as SpinnerIcon } from '../assets/icons/spinner.svg';
import { ReactComponent as ValidateIcon } from '../assets/icons/validate.svg';


const BookingForm = ({ mufti, onConfirm }) => {
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        callSubject: '',
      });
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const [loading, setLoading] = useState(false);  // Loading state for form submission
    const [error, setError] = useState(null);
    const errorRef = useRef(null);
    
    useEffect(() => {
        if (error && errorRef.current) {
            errorRef.current.focus();
        }
    }, [error]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Set loading to true when submission starts
        setLoading(true);

        // Validate form data
        const validationError = validateBookingCall(formData, recaptchaToken, t);
        if (validationError) {
            setError(validationError);
            setLoading(false);  // Stop loading if validation fails
            return;
        }

        try {
            await bookCall(formData, mufti.id, recaptchaToken);
            setError(null);
        } catch (error) {
            setError(t('bookingForm.submissionError'));
        } finally {
            setLoading(false);  // Stop loading when submission is complete
        }

        onConfirm({
            ...formData,
            mufti
        });
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
      };
    
    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };

    return (
        <div className="p-6 bg-gray-50 min-h-screen">
            <h2 className="text-2xl font-semibold mb-6 text-center">
                {t('bookingForm.title', { muftiName: mufti.name })}
            </h2>
            
            <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md">
                {error && (
                    <p 
                        ref={errorRef} 
                        tabIndex="-1" 
                        className="text-red-500 my-4" 
                        aria-live="assertive"  // Inform assistive technologies about changes
                    >
                        {error}
                    </p>
                )}
                <div className="space-y-4">
                    <div className="input-group">
                        <label className="block text-gray-700">{t('bookingForm.firstName')}</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="input-group">
                        <label className="block text-gray-700">{t('bookingForm.lastName')}</label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="input-group">
                        <label className="block text-gray-700">{t('bookingForm.email')}</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="input-group">
                        <label className="block text-gray-700">{t('bookingForm.phone')}</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="input-group">
                        <label className="block text-gray-700">{t('bookingForm.callSubject')}</label>
                        <textarea
                            type="text"
                            name="callSubject"
                            value={formData.callSubject}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded"
                        ></textarea>
                    </div>
                    <ReCAPTCHA
                    sitekey={recaptchaSiteKey}
                    onChange={handleRecaptchaChange}
                    />
                    <button
                        type="submit"
                        className="w-full bg-gradient-green-r text-white py-2 px-4 rounded hover:bg-green-600 transition"
                        disabled={loading}  // Disable button when loading
                    >
                        {loading ? (
                            <span className="flex justify-center items-center">
                            <SpinnerIcon className={`animate-spin w-5 h-5 ${i18n.language === "ar" ? "ml-2" : "mr-2"}`} /> 
                            {t('bookingForm.submitting')}
                            </span>
                        ) : (
                            <span className="flex justify-center items-center">
                                <ValidateIcon className={`w-5 h-5 ${i18n.language === "ar" ? "ml-2" : "mr-2"}`} /> 
                                {t('bookingForm.confirmBooking')}
                            </span>  
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default BookingForm;
