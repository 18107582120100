import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { validateSubmitQuestion } from '../utils/validation';
import { submitQuestion, getQuestionByRefNo } from '../services/apiService';
import { ReactComponent as MessageSentIcon } from '../assets/icons/message-sent.svg';
import { ReactComponent as SpinnerIcon } from '../assets/icons/spinner.svg';
import { ReactComponent as SubmitSuccessIcon } from '../assets/icons/submit-success.svg';
import styles from '../styles/pages/SubmitQuestion.module.css';

const SubmitQuestion = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    title: '',
    question: ''
  });

  const [question, setQuestion] = useState(null);

  const [refNo, setRefNo] = useState('');
  const [statusRefNo, setStatusRefNo] = useState('');
  const [error, setError] = useState(null);
  const [submitQuestionSuccess, setSubmitQuestionSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const errorRef = useRef(null);
  
  const [activeTab, setActiveTab] = useState('askQuestion'); 

  useEffect(() => {
    if (error && errorRef.current) {
        errorRef.current.focus();
    }
  }, [error]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleBackClick = () => {
    navigate('/'); // Navigate back to Home page
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmitQuestion = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const validationError = validateSubmitQuestion(formData, recaptchaToken, t);
    if (validationError) {
      setError(validationError);
      setLoading(false);
      return;
    }

    try {
      const response = await submitQuestion(formData, recaptchaToken);
      setRefNo(response.refNo);
      setSubmitQuestionSuccess(true);
    } catch (error) {
      setError(t('submitQuestion.submissionError'));
    } finally {
      setLoading(false);
    }
  };

  const handleCheckStatus = (e) => {
    e.preventDefault();
    const fetchQuestion = async () => {
      try {
        // Fetch the question by refNo
        const data = await getQuestionByRefNo(statusRefNo);
        setQuestion(data);
      } catch (error) {
        setError(t('fatwaDetail.errorFetchingFatwa'));
      } finally {
        setLoading(false);
      }
    };

    fetchQuestion();
  };

  const renderAskQuestionForm = () => (
    <div className={`${styles.submitQuestionContainer} max-w-xl mx-auto py-10 px-20 rounded-lg shadow-lg mt-10`}>
      <div className="background-image-right-bottom-corner"></div>
      <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">{t('submitQuestion.title')}</h2>
      {error && (
        <p 
            ref={errorRef} 
            tabIndex="-1" 
            className="text-red-500 mb-4" 
            aria-live="assertive"  // Inform assistive technologies about changes
        >
            {error}
        </p>
      )}
      <form onSubmit={handleSubmitQuestion} className="space-y-4">
          <div>
            <label className="block text-gray-700">{t('submitQuestion.firstNameLabel')}:</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-gray-700">{t('submitQuestion.lastNameLabel')}:</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
            />
          </div>
          <div className="input-group">
            <label className="block text-gray-700">{t('submitQuestion.phoneLabel')}</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
            />
          </div>
        <div>
          <label className="block text-gray-700">{t('submitQuestion.titleLabel')}:</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
          />
        </div>
        <div>
          <label className="block text-gray-700">{t('submitQuestion.questionLabel')}:</label>
          <textarea
            name="question"
            value={formData.question}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
          ></textarea>
        </div>

        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={handleRecaptchaChange}
        />

        <button
          type="submit"
          className="w-full py-2 px-4 bg-gradient-green-r text-button-white font-semibold rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          disabled={loading}  // Disable button when loading
        >
          {loading ? (
            <span className="flex justify-center items-center">
              <SpinnerIcon className={`animate-spin w-5 h-5 ${i18n.language === "ar" ? "ml-2" : "mr-2"}`} /> 
              {t('submitQuestion.submitting')}
            </span>
          ) : (
            <span className="flex justify-center items-center">
              <SubmitSuccessIcon className={`w-5 h-5 ${i18n.language === "ar" ? "ml-2" : "mr-2"}`} /> 
              {t('submitQuestion.submitButton')}
            </span>
          )}
        </button>
      </form>
    </div>
  );

  const renderCheckStatusForm = () => (
    <div className={`${styles.checkQuestionStatusContainer} max-w-xl mx-auto py-10 px-20 rounded-lg shadow-lg mt-10`}>
      <form onSubmit={handleCheckStatus} className="space-y-4">
        <div>
          <label className="block text-gray-700">{t('submitQuestion.statusRefNoLabel')}:</label>
          <input
            type="text"
            name="statusRefNo"
            value={statusRefNo}
            onChange={(e) => setStatusRefNo(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 bg-gradient-green-r text-button-white font-semibold rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
          {t('submitQuestion.checkStatusButton')}
        </button>
      </form>
    </div>
  );

  if (submitQuestionSuccess) {
    return (
      <div>
        <div className="background-image-right-bottom-corner"></div>
        <div className="max-w-lg mx-auto bg-white bg-opacity-50 p-16 rounded-lg shadow-lg mt-10 text-center">
          <MessageSentIcon className="mx-auto mb-4 w-13 h-12" />
          <h2 className="text-3xl font-bold text-green-600 mb-4">{t('submitQuestion.successTitle')}</h2>
          <p className="text-gray-700 mb-2">{t('submitQuestion.successMessage')}</p>
          <p className="text-gray-700 mb-6">{t('submitQuestion.refNo')}: {refNo}</p>
          <button
            className="bg-gradient-green-r text-button-white font-bold py-2 px-6 rounded hover:bg-blue-600"
            onClick={handleBackClick}
          >
            {t('submitQuestion.backToHome')}
          </button>
        </div>
      </div>
    );
  }
  if (question) {
    return (
      <div>
        <div className="background-image-right-bottom-corner"></div>
        <div className="max-w-lg mx-auto bg-white bg-opacity-50 p-16 rounded-lg shadow-lg my-10 text-center">
          <h2 className="text-3xl font-bold text-green-600 mb-4 text-center">{t('submitQuestion.questionDetails')}</h2>
          <p className={`text-gray-700 mb-6 ${isRTL ? 'text-right' : 'text-left'}`}>{t('submitQuestion.refNo')}: {question.refNo}</p>
          <p className={`text-gray-700 mb-6 ${isRTL ? 'text-right' : 'text-left'}`}>{t('submitQuestion.questionStatus')}: {question.status}</p>
          <button
            className="bg-gradient-green-r w-full text-button-white font-bold py-2 px-6 rounded hover:bg-blue-600"
            onClick={handleBackClick}
          >
            {t('submitQuestion.backToHome')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Tab Navigation */}
      <div className="max-w-xl w-full mx-auto pt-2">
        <div className="flex flex-row">
          <button
            className={`flex-1 px-4 py-2 rounded text-white ${activeTab === 'askQuestion' ? 'bg-secondary' : 'bg-primary'}`}
            onClick={() => setActiveTab('askQuestion')}
          >
            {t('submitQuestion.askQuestion')}
          </button>
          <button
            className={`flex-1 px-4 py-2 rounded text-white ${activeTab === 'checkStatus' ? 'bg-secondary' : 'bg-primary'}`}
            onClick={() => setActiveTab('checkStatus')}
          >
            {t('submitQuestion.checkStatus')}
          </button>
        </div>
      </div>


      {/* Tab Content */}
      {activeTab === 'askQuestion' ? renderAskQuestionForm() : renderCheckStatusForm()}
    </>
  );
};

export default SubmitQuestion;
