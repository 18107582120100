import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFatwas, getFatwasCategories } from '../services/apiService';
import '../styles/main.css';
import useIsMobileDevice from '../components/useIsMobileDevice';
import { ReactComponent as AskQuestionIcon } from '../assets/icons/question.svg';
import { ReactComponent as BookCallIcon} from '../assets/icons/book-video-call.svg';
import styles from '../styles/pages/Home.module.css';

const Home = () => {
    const [fatwas, setFatwas] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [categories, setCategories] = useState([]);
    const [suggestions, setSuggestions] = useState([]); // State for autocomplete suggestions
    const [errorFetchingSuggestions, setErrorFetchingSuggestions] = useState(null);
    const [showSuggestions, setShowSuggestions] = useState(false); // State for suggestion visibility
    const [loadingFatwasCategories, setLoadingFatwasCategories] = useState(true);
    const [errorFetchingFatwasCategories, setErrorFetchingFatwasCategories] = useState(null);
    const [loadingFatwas, setLoadingFatwas] = useState(true);
    const [errorFetchingFatwas, setErrorFetchingFatwas] = useState(null);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isMobileDevice = useIsMobileDevice();
    const isArabic = i18n.language === 'ar';

    useEffect(() => {
        const fetchFatwas = async () => {
            try {
                // Fetch all fatwas
                const data = await getFatwas();

                // Sort Fatwas by date (most recent first)
                const sortedFatwas = data.fatwas.sort((a, b) => new Date(b.date) - new Date(a.date));
                
                // Select the first three Fatwas
                const latestThreeFatwas = sortedFatwas.slice(0, 3);

                // Set the state with the latest three Fatwas
                setFatwas(latestThreeFatwas);
              } catch (error) {
                setErrorFetchingFatwas(t('home.errorFetchingFatwas'));
              } finally {
                setLoadingFatwas(false);
              }
        };

        fetchFatwas();
    }, [t]);

    useEffect(() => {
        // Fetch categories
        const fetchCategories = async () => {
            try {
                const data = await getFatwasCategories();
                setCategories(data);
            } catch (error) {
                setErrorFetchingFatwasCategories(t('home.errorFetchingFatwasCategories'));
            } finally {
                setLoadingFatwasCategories(false);
            }
        };
        fetchCategories();
    }, [t]);

    useEffect(() => {
        // Update suggestions based on search term
        if (searchTerm) {
            // Search for Fatwas based on search term
            const getSuggestions = async () => {
                try {
                    const data = await getFatwas({searchQuery: searchTerm});
                    setSuggestions(
                        data.fatwas.map(
                            fatwa => {
                                return {title: fatwa.title, refNo: fatwa.refNo}
                            }
                        )
                    );
                }  catch (error) {
                    setErrorFetchingSuggestions(t('home.errorFetchingSuggestions'));
                }
                
            };
            getSuggestions();
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    }, [searchTerm, t]);

    const handleSubmitQuestionButtonClick = () => {
        navigate('/submit-question');
    };
    
    const handleSearchInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            // Redirect to FatwaEncyclopedia with the search term as a query parameter
            navigate(`/encyclopedia?searchQuery=${searchTerm}`);
        }
    };
    
    const handleCategoryClick = (categoryId) => {
        navigate(`/encyclopedia?categoryId=${categoryId}`);
    };


    // Handles suggestion click
    const handleSuggestionClick = (fatwaRefNo) => {
        setShowSuggestions(false);
        navigate(`/encyclopedia?refNo=${fatwaRefNo}`);
    };

    const handleVideoCallButtonClick = () => {
        navigate('/video-call-booking');
    };

    return (
        <div className={styles.homePage}>
            <header className={`${styles.header} bg-gradient-green-${isArabic ? 'r' : 'l'} text-white text-center py-20`}>
                <h1 className="text-3xl">{t('home.header.quote')}</h1>
                <div className={`${isMobileDevice ? styles.searchBarMobile : styles.searchBar} mt-6 relative`}>
                    <input
                        type="text"
                        placeholder={t('home.search.placeholder')}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyUp={handleSearchInputKeyPress}
                        className={`${styles.input} w-full max-w-lg px-4 py-3 rounded-md border-none text-gray-800`}
                    />
                    {showSuggestions && (
                        <div className={`${styles.suggestionsDropdown} absolute ${!isMobileDevice ? 'w-[43%]' : '' } text-black mt-12 rounded-md shadow-lg z-50`}>
                            {errorFetchingSuggestions && (
                                <div className="px-4 pt-3 pb-2 rounded-md text-red-500">
                                    {t('home.errorFetchingSuggestions')}
                                </div>
                            )}
                            {!errorFetchingSuggestions && suggestions.length > 0 ? (
                                suggestions.map((suggestion, index) => {
                                    const parts = suggestion.title.split(new RegExp(`(${searchTerm})`, 'gi'));
    
                                    return (
                                        <button
                                            key={index}
                                            onClick={() => handleSuggestionClick(suggestion.refNo)}
                                            className="block w-full px-4 pt-3 hover:bg-gray-200 rounded-md"
                                        >
                                            <div className="border-b border-gray-300 mx-2 pb-2">
                                                {parts.map((part, i) => (
                                                    <span
                                                        key={i}
                                                        className={
                                                            part.toLowerCase() === searchTerm.toLowerCase()
                                                                ? "text-green-700 font-semibold"
                                                                : ""
                                                        }
                                                    >
                                                        {part}
                                                    </span>
                                                ))}
                                            </div>
                                        </button>
                                    );
                                })
                            ) : (
                                <div className={`${styles.noSuggestions} px-4 pt-3 pb-2 rounded-md`}>
                                    {t('home.search.noSuggestions')}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </header>
            <section className={styles.fatwaActions}>
                <button
                    className={`${isMobileDevice ? styles.fatwaActionMobile : styles.fatwaAction} bg-gradient-green-r text-button-white py-2 px-6 rounded-lg`}
                    onClick={handleVideoCallButtonClick}
                >
                    <span className="flex justify-center items-center" style={{ direction: isArabic ? "rtl" : "ltr" }}>
                        <BookCallIcon className={`w-5 h-5 ${isArabic ? "ml-2" : "mr-2"}`} /> 
                        {t('home.fatwaActions.bookCall')}
                    </span>
                </button>
                <button
                    className={`${isMobileDevice ? styles.fatwaActionMobile : styles.fatwaAction} bg-gradient-green-r text-button-white py-2 px-6 rounded-lg`}
                    onClick={handleSubmitQuestionButtonClick}
                >
                    <span className="flex justify-center items-center" style={{ direction: isArabic ? "rtl" : "ltr" }}>
                        <AskQuestionIcon className={`w-5 h-5 ${isArabic ? "ml-2" : "mr-2"}`} /> 
                        {t('home.fatwaActions.askQuestion')}
                    </span>
                </button>
                
            </section>
    
            <div className={`${styles.recentFatwasAndCategoriesSection} flex ${isMobileDevice ? 'flex-col' : (isArabic ? 'flex-row-reverse' : 'flex-row')} justify-end px-4`}>
                <section className={`${styles.categories} ${!isMobileDevice && 'w-1/4'}`}>
                    <div className={`${styles.categoriesDropdown} rounded-md shadow-lg ring-1 ring-black ring-opacity-5`}>
                        <div className="py-1">
                            {loadingFatwasCategories ? <div className='block text-center text-black'>{ t('home.loadingFatwasCategories') }</div> : (
                                errorFetchingFatwasCategories ? <div className='block text-center text-black'>{ t('home.errorFetchingFatwasCategories') }</div> : (
                                    categories.map((category) => (
                                        <button
                                            key={category.id}
                                            onClick={() => handleCategoryClick(category.id)}
                                            className="block hover:bg-gray-200"
                                        >
                                            {category.name}
                                        </button>
                                    ))
                                )
                            )}
                        </div>
                    </div>
                    
                </section>
                <section className={`${styles.recentFatwas} ${!isMobileDevice && 'w-3/4'}`}>
                    {loadingFatwas ? <div className='bg-white p-4 rounded-md shadow-md mb-4 text-center'>{ t('home.loadingFatwas') }</div> : (
                        errorFetchingFatwas ? <div className='bg-white p-4 rounded-md shadow-md mb-4 text-center'>{ t('home.errorFetchingFatwas') }</div> : (
                            fatwas.map((fatwa, index) => (
                                <div className={`${isMobileDevice ? styles.fatwaItemMobile : styles.fatwaItem} bg-white p-4 rounded-md shadow-md mb-4`} key={index}>
                                    <div className={`flex justify-between ${!isArabic ? 'flex-row-reverse' : ''}`}>
                                        <p className={`${isArabic ? 'text-right' : 'text-left'}`}>
                                            <strong>{fatwa.title}</strong>
                                        </p>
                                        <div className={styles.fatwaInfo}>
                                            <span className="text-gray-600">
                                            {fatwa.date} | {t('home.fatwaItem.refNo')}: {fatwa.refNo}
                                            </span>
                                        </div>
                                        </div>

                                    <p className={`${isArabic ? 'text-right' : 'text-left'}`}>
                                        {fatwa.question.length > 100 ? (
                                        <>
                                            {fatwa.question.slice(0, 100)}...
                                        </>
                                        ) : fatwa.question}
                                        <Link to={`/fatwa/${fatwa.refNo}`} className="text-color-green hover:underline">
                                            {t('home.fatwaItem.readMore')}
                                        </Link>
                                    </p>
                                    
                                </div>
                            ))
                        )
                    )}
                </section>

                
                
            </div>
        </div>
    );
    
    
    
    
};

export default Home;
