import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/pages/FatwaEncyclopedia.module.css';
import { useTranslation } from 'react-i18next';
import useFatwaSearch from '../components/useFatwaSearch';

const FatwaEncyclopedia = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const { t } = useTranslation();

  const {
    fatwas,
    hasMore,
    loading,
    error,
    searchQuery
  } = useFatwaSearch(searchTerm, pageNumber)

  if (!searchTerm && searchQuery) {
    setSearchTerm(searchQuery);
  }

  const observer = useRef()
  const lastFatwaElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    setPageNumber(1);
  }

  const fatwaCard = (fatwa) => {
    return (
      <>
        <div className={styles.encyclopediaHeader}>
          <p className={styles.encyclopediaItemTitle}><strong>{fatwa.title}</strong></p>
          <div className={`${styles.encyclopediaItemInfo} text-gray-600`}>
            <span>{fatwa.date} | {t('fatwaEncyclopedia.refNo')}: {fatwa.refNo}</span>
          </div>
        </div>
        <p>
            {fatwa.question.length > 200 ? (
            <>
                {fatwa.question.slice(0, 200)}...
            </>
            ) : fatwa.question}
            <Link to={`/fatwa/${fatwa.refNo}`} className="text-color-green hover:underline">
                {t('fatwaEncyclopedia.readMore')}
            </Link>
        </p>
      </>
    )
  }

  return (
    <div className={`${styles.encyclopediaContainer} p-8`}>
      <h1 className={`${styles.encyclopediaTitle} text-2xl font-bold mb-4`}>{t('fatwaEncyclopedia.title')}</h1>
      <input
        type="text"
        className={`${styles.encyclopediaSearch} border rounded p-2 mb-4 w-full`}
        placeholder={t('fatwaEncyclopedia.searchPlaceholder')}
        value={searchTerm}
        onChange={handleSearchInputChange}
      />
      {loading && <div className="text-center text-gray-500 mt-5">{t('fatwaEncyclopedia.loading')}</div>}
      {error && <div className="text-center text-gray-500 mt-5">{error}</div>}
      {(!loading && !fatwas.length) && <div className="text-center text-gray-500 mt-5">{t('fatwaEncyclopedia.noResults')}</div>}

      <div className={`${styles.encyclopediaList} space-y-4`}>
        {fatwas.map((fatwa, index) => (
            // Book card
            fatwas.length === index + 1 ? (
              <div key={index} ref={lastFatwaElementRef} className={`${styles.encyclopediaItem} bg-white p-4 rounded-md shadow-md mb-4`}>
                {fatwaCard(fatwa)}
              </div>
            ) : (
              <div key={index} className={`${styles.encyclopediaItem} bg-white p-4 rounded-md shadow-md mb-4`}>
                {fatwaCard(fatwa)}
              </div>
            )
        ))}
      </div>
      {loading && (
          <div className="spinner">
              <div></div>
              <div></div>
              <div></div>
          </div>
      )}
    </div>
  );
}

export default FatwaEncyclopedia;
