import React from 'react';
import { useTranslation } from 'react-i18next';

const Donate = () => {
  const { t } = useTranslation();

  return (
    <div className="donate-page-container">
        <div className="background-image-right-bottom-corner"></div>
        <div className="max-w-xl mx-auto mt-10 bg-white bg-opacity-50 p-8 rounded-lg shadow-md">
            <h1 className="text-3xl font-bold mb-4 text-center text-gray-800">{t('donate.title')}</h1>
            <p className="text-lg text-gray-700 leading-relaxed">
                {t('donate.first_paragraph')}
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mt-4">
                {t('donate.second_paragraph')}
            </p>
        </div>
    </div>
  );
}

export default Donate;
