import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import useIsMobileDevice from './useIsMobileDevice';
import FatwaActionsSlider from './FatwaActionsSlider';
import { ReactComponent as HomeIcon } from '../assets/icons/home.svg';
import { ReactComponent as OwnerIcon } from '../assets/icons/owner.svg';
import { ReactComponent as DonateIcon } from '../assets/icons/donate.svg';
import { ReactComponent as BooksIcon } from '../assets/icons/books.svg';
import { ReactComponent as EncyclopediaIcon } from '../assets/icons/encyclopedia.svg';
import styles from '../styles/components/NavBar.module.css';


const Navbar = () => {
  // State to manage mobile menu visibility
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isMobileDevice = useIsMobileDevice();

  const currentLocation = useLocation();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const navbarItems = [
    { label: t('navbar.home'), path: '/', icon: <HomeIcon style={{ width: '24px', height: '24px' }} /> },
    { label: t('navbar.about'), path: '/about', icon: <OwnerIcon style={{ width: '24px', height: '24px' }} /> },
    { label: t('navbar.donate'), path: '/donate', icon: <DonateIcon style={{ width: '24px', height: '24px' }} /> },
    { label: t('navbar.books'), path: '/books', icon: <BooksIcon style={{ width: '24px', height: '24px' }} /> },
    { label: t('navbar.encyclopedia'), path: '/encyclopedia', icon: <EncyclopediaIcon style={{ width: '24px', height: '24px' }} /> },
  ];

  const navbarLinks = (
    <ul className={`${styles.navbarMenu} flex justify-start ${isMobileMenuOpen ? 'flex-col' : ''}`}>
      {navbarItems.map((item, index) => (
        <li
          key={index}
          className={`${styles.navbarItem} ${isArabic ? 'hover:bg-gradient-green-r' : 'hover:bg-gradient-green-l'} hover:text-white ${currentLocation.pathname === item.path && (isMobileDevice ? 'text-medium-green' : (isArabic ? 'bg-gradient-green-r text-white' : 'bg-gradient-green-l text-white'))}`}>
        <Link
          to={item.path}
          className={`${styles.navbarLink} `}
          onClick={() => setIsMobileMenuOpen(false)}
        >
          {/* Conditional rendering for mobile device with flex layout */}
          {isMobileDevice && (
            <span className={`flex flex-row items-center`}>
              {/* Icon */}
              <span className={`${isArabic ? 'ml-2' : 'mr-2'}`}>{item.icon}</span>
              {/* Label */}
              <span>{item.label}</span>
            </span>
          )}
          {/* Default label without icon for larger screens */}
          {!isMobileDevice && <span>{item.label}</span>}
        </Link>
      </li>
      
        ))}
    </ul>
  );

  return (
    <nav className={`${styles.navbar} flex flex-col justify-end`}>
      <LanguageSwitcher />
        
        {isMobileDevice && (
          <section className='py-2 bg-light-green flex justify-between items-center'>
          {/* Hamburger Menu Icon */}
          <div
            className='px-4 flex items-center'
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <i className={isMobileMenuOpen ? 'fas fa-times' : 'fas fa-bars'} style={{ fontSize: '1.5rem' }}></i>
          </div>
          {/* Logo */}
          <div className="">
            <Link to="/">
              <img
                src="/app/logo192.png"
                alt="FATWA"
                className="h-20 md:h-24 lg:h-28"
              />
            </Link>
          </div>
          </section>
        )}

        {/* Navbar Links Desktop */}
        {!isMobileDevice && (
          <section className={`${styles.navbarContainer} bg-light-green flex justify-between items-center`}>
          {navbarLinks}
          {/* Logo */}
          <div className="">
              <Link to="/">
                <img
                  src="/app/logo192.png"
                  alt="FATWA"
                  className="h-10 md:h-12 lg:h-14"
                />
              </Link>
            </div>
          </section>
        )}

      {/* Navbar Links Mobile */}
      {(isMobileDevice && isMobileMenuOpen) && navbarLinks}

      {/* Slider */}
      {currentLocation.pathname !== '/' && <FatwaActionsSlider />}
    </nav>
  );
};

export default Navbar;
