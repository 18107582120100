import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getFatwaByRefNo, getQuestionByRefNo } from '../services/apiService';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ReturnBackwardIcon} from '../assets/icons/return-arrow.svg';
import styles from '../styles/pages/FatwaDetail.module.css';

const FatwaDetail = () => {
  const { refNo } = useParams();
  const navigate = useNavigate();
  const [fatwa, setFatwa] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  // Parse query parameters from the URL
  const params = new URLSearchParams(location.search);
  const type = params.get('type');

  useEffect(() => {
    const fetchFatwa = async () => {
      try {
        // Fetch the fatwa by refNo
        if (type === 'question') {
          const data = await getQuestionByRefNo(refNo);
          setFatwa(data);
        } else {
          const data = await getFatwaByRefNo(refNo);
          setFatwa(data);
        }
      } catch (error) {
        setError(t('fatwaDetail.errorFetchingFatwa'));
      } finally {
        setLoading(false);
      }
    };

    fetchFatwa();
  }, [refNo, t, type]);

  if (loading) return <div className="text-center text-gray-500 mt-5">{t('fatwaDetail.loading')}</div>;
  if (error) return <div className="text-center text-gray-500 mt-5">{error}</div>;
  if (!fatwa) return <div className="text-center text-gray-500 mt-5">{t('fatwaDetail.noResults')}</div>;

  const handleBackClick = () => {
    navigate('/encyclopedia'); // Navigate back to the Fatwa Encyclopedia
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      <div className="background-image-right-bottom-corner"></div>
      <div className={`${styles.fatwaQuestion} mb-4`}>
        <h2 className="text-2xl font-bold mb-4 text-center">{fatwa.title}</h2>
        <p className="text-gray-700 mb-2">
          <strong>{t('fatwaDetail.questionLabel')} :</strong>
        </p>
        <p className="text-gray-700">{fatwa.question}</p>
      </div>
      <div className={`${styles.fatwaAnswer} mb-4`}>
        <p className="text-gray-700 mb-2">
          <strong>{t('fatwaDetail.answerLabel')} :</strong>
        </p>
        <p className="text-gray-700">{fatwa.answer}</p>
      </div>
      <div className="text-center mt-6">
        <button
          className="bg-gradient-green-r text-button-white font-bold py-2 px-4 rounded hover:bg-blue-600"
          onClick={handleBackClick}
        >
          <span className="flex justify-center items-start">
            <ReturnBackwardIcon className={`w-5 h-5 ${i18n.language === "ar" ? "ml-2" : "mr-2"}`} /> 
            {t('fatwaDetail.backButton')}
          </span>
        </button>
      </div>
    </div>
  );
};

export default FatwaDetail;
