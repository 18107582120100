import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getMuftis } from '../services/apiService';
import useIsMobileDevice from './useIsMobileDevice';
import { ReactComponent as BookCallIcon} from '../assets/icons/book-video-call.svg';

const MuftiList = ({ onSelectMufti }) => {
    const [muftis, setMuftis] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [hasMore, setHasMore] = useState(true); // Track if there are more muftis to load
    const [page, setPage] = useState(1); // Current page
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const isMobileDevice = useIsMobileDevice();

    useEffect(() => {
        // Fetch Muftis with pagination
        const fetchMuftis = async () => {
            setLoading(true);
            try {
                const params = {page};
                if (searchTerm) {
                    params.searchQuery = searchTerm;
                }
                const data = await getMuftis(params);
                setMuftis(data.muftis);
                setHasMore(data.hasMore); // Check if there are more muftis to load
            } catch (error) {
                setError(t('muftiList.errorFetchingMuftis'));
            } finally {
                setLoading(false);
            }
        };
        fetchMuftis();
    }, [page, t, searchTerm]);

    // Handler for filter input changes
    const handleFilterChange = (e) => {
        setMuftis([]);
        setPage(1);
        setSearchTerm(e.target.value);
    };

    // Infinite scroll handler
    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (!loading && hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
    }, [loading, hasMore]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const bookCallButton = (mufti) => (
        <button
            onClick={() => onSelectMufti(mufti)}
            className={`bg-gradient-green-r text-white py-2 px-4 rounded hover:bg-green-600 transition flex-shrink-0 ${isMobileDevice ? "w-full mx-auto mt-5" : "self-center"}`}
            style={{ height: '40px' }}
            >
            <span className="flex justify-center items-center">
                <BookCallIcon className={`w-5 h-5 ${i18n.language === "ar" ? "ml-2" : "mr-2"}`} />
                {t('muftiList.bookCall')}
            </span>
        </button>
    );

    return (
        <div className="mufti-list max-w-xl mx-auto p-6 min-h-screen">
            <h2 className="text-2xl font-semibold mb-6 text-center">{t('muftiList.title')}</h2>
            
            <div className="mb-6">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleFilterChange}
                    placeholder={t('muftiList.filterPlaceholder')}
                    className="p-2 border rounded w-full"
                />
            </div>

            {loading && <div className="text-center text-gray-500 mt-5">{t('muftiList.loading')}</div>}
            {error && <div className="text-center text-gray-500 mt-5">{error}</div>}
            {(!loading && !muftis.length) && <div className="text-center text-gray-500 mt-5">{t('muftiList.noResults')}</div>}

            <ul className="space-y-4">
                {muftis.map((mufti) => (
                    <div key={mufti.id} className="bg-white p-4 rounded-lg shadow-md hover:bg-gray-100 transition ">
                        <li  className="flex items-start">
                            {/* Mufti Image or Icon */}
                            <img
                                src={mufti.image}
                                alt={mufti.name}
                                className={`w-16 h-16 rounded-full ${i18n.dir() === 'rtl' ? 'ml-4' : 'mr-4'} flex-shrink-0`}
                            />

                            {/* Mufti Details and Quote */}
                            <div className="flex-grow flex flex-col justify-between">
                                <div>
                                    <h3 className="text-xl font-medium">{mufti.name}</h3>
                                    <p className="text-gray-600">{t('muftiList.specialties')}: {mufti.specialties && mufti.specialties.join(isArabic ? "، " : " ,")}</p>
                                </div>
                                <p className="italic text-sm text-gray-500 mt-2">"{mufti.quote}"</p>
                            </div>

                            {/* Book Call Button */}
                            {!isMobileDevice && bookCallButton(mufti)}
                        </li>
                        {/* Book Call Button */}
                        {isMobileDevice && bookCallButton(mufti)}
                    </div>
                ))}
            </ul>

            {loading && (
                <div className="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )}
        </div>
    );
};

export default MuftiList;
