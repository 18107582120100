import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <div>
        <div className="background-image-right-bottom-corner"></div>
        <div className="max-w-lg mx-auto bg-white bg-opacity-50 p-8 rounded-lg mt-10 shadow-md">
            <h1 className="text-3xl font-bold mb-4 text-center text-gray-800">{t('about.title')}</h1>
            <p className="text-lg text-gray-700 leading-relaxed">
                {t('about.description')}
            </p>
        </div>
    </div>
  );

}

export default About;
