import axios from "axios";
import i18n from 'i18next';

const API_BASE_URL = 'https://compos.hopto.org';

const formatFatwa = (fatwa) => {
    let mapping = {
        "fatwa": "answer",
        "id": "refNo",
        "date_publication": "date",
    }
    // Map the keys to the desired format
    const mappedFatwa = {};
    for (const key in fatwa) {
        if (mapping[key]) {
            mappedFatwa[mapping[key]] = fatwa[key];
        }
        else {
            mappedFatwa[key] = fatwa[key];
        }
    }
    return mappedFatwa;
};

const formatQuestion = (question) => {
    let mapping = {
        "uuid": "refNo",
        "message": "question"
    }
    // Map the keys to the desired format
    const mappedQuestion = {};
    for (const key in question) {
        if (mapping[key]) {
            mappedQuestion[mapping[key]] = question[key];
        }
        else {
            mappedQuestion[key] = question[key];
        }
    }
    return mappedQuestion;
};

const formatBook = (book) => {
    let mapping = {
        "cover_url": "coverUrl"
    }
    // Map the keys to the desired format
    const mappedBook = {};
    for (const key in book) {
        if (mapping[key]) {
            mappedBook[mapping[key]] = book[key];
        }
        else {
            mappedBook[key] = book[key];
        }
    }
    return mappedBook;
};

export const getFatwaByRefNo = async (refNo) => {
    // Get fatwa with specific Id (refNo)
    let fatwa = await fetch(`${API_BASE_URL}/fatwa/${refNo}`, {
            headers: {
                'Accept-Language': i18n.language,
            }
        }).then(response => response.json())
        .then(data => {
            if (data) {
                return formatFatwa(data);
            } else {
                throw new Error("Failed to fetch fatwa with refNo: " + refNo);
            }
        });
    return fatwa
};

export const getQuestionByRefNo = async (refNo) => {
    // Get Question with specific UUID (refNo)
    let fatwa = await fetch(`${API_BASE_URL}/question/${refNo}`, {
            headers: {
                'Accept-Language': i18n.language,
            }
        }).then(response => response.json())
        .then(data => {
            if (data) {
                return formatQuestion(data);
            } else {
                throw new Error("Failed to fetch question with refNo: " + refNo);
            }
        });
    return fatwa
};

export const getFatwasByUserId = async (userId) => {
    // Get user questions from fatwa.bo.composdz.com/questions
    let userQuestions = await fetch(`${API_BASE_URL}/questions?userId=${userId}`, {
            headers: {
                'Accept-Language': i18n.language,
            }
        }).then(response => response.json())
        .then(data => {
            if (data.success) {
                return data.questions.map(question => formatQuestion(question));
            } else {
                throw new Error("Failed to fetch questions");
            }
        });

    return userQuestions;
};

export const getFatwas = async ({ categoryId, searchQuery, refNo, page } = {page: 0}) => {
    if (refNo) {
        // Get fatwa with specific Id (refNo)
        return {
            fatwas: [await getFatwaByRefNo(refNo)],
            hasMore: false,
            cancelToken: null,
            error: null,
        }
    }

    const perPage = 6;
    const cancelTokenSource = axios.CancelToken.source();
    let total = 0;
    let args = {};
    let error = null;
    let filteredFatwas = [];

    if (categoryId) {
        args = {...args, category_id: categoryId};
    }
    if (searchQuery) {
        args = {...args, search: searchQuery};
    }
    if (page) {
        args = {...args, page: page, page_size: perPage};
    }
    
    try {
        // Get all fatwas from fatwa.bo.composdz.com/fatwas
        const response = await axios.get(`${API_BASE_URL}/fatwas`, {
            params: args,
            cancelToken: cancelTokenSource.token,
            headers: {
                'Accept-Language': i18n.language,
            }
        });

        if (response.status === 200 && response.data.success) {
            total = response.data.total;
            filteredFatwas = response.data.fatwas.map(fatwa => formatFatwa(fatwa))
        } else {
            error = "Failed to Fetch Fatwas";
        }
    } catch (e) {
        error = e;
    }
    
    if (!page || page === 0) {
        return {
            fatwas: filteredFatwas,
            hasMore: false,
            cancelTokenSource: cancelTokenSource,
            error: error,
        };
    }
    return {
        fatwas: filteredFatwas,
        hasMore: total > page * perPage,
        cancelTokenSource: cancelTokenSource,
        error: error,
    };
};

export const getFatwasCategories = async () => {
  try {
    // Fetch fatwa categories from the API
    const response = await fetch(`${API_BASE_URL}/fatwa-categories`, {
      headers: {
        'Accept-Language': i18n.language,
      },
    });

    const data = await response.json();

    if (data.success) {
      return data.categories;
    } else {
      throw new Error("Failed to fetch fatwa categories");
    }
  } catch (error) {
    throw error;
  }
};

export const getMuftis = async ({ page, searchQuery } = { page: 0 }) => {
    // Get all muftis from fatwa.bo.composdz.com/muftis
    const perPage = 4;
    let total = 0;
    let args = [];
    if (searchQuery) {
        args.push(`search=${searchQuery}`);
    }
    if (page) {
        args.push(`page=${page}`);
        args.push(`page_size=${perPage}`);
    }
    args = args.join("&");
    let filteredMuftis = await fetch(`${API_BASE_URL}/muftis${args ? "?" + args : ""}`, {
        headers: {
          'Accept-Language': i18n.language,
        },
      }).then(response => response.json())
        .then(data => {
            if (data.success) {
                total = data.total;
                return data.muftis;
            } else {
                throw new Error("Failed to fetch Muftis");
            }
        });

    if (!page || page === 0) {
        return {
            muftis: filteredMuftis,
            hasMore: false,
        };
    }
    return {
        muftis: filteredMuftis,
        hasMore: total > page * perPage,
    };
};

export const getBooks = async ({page, searchQuery} = {page: 0}) => {
    // Get all books from fatwa.bo.composdz.com/books
    const perPage = 6;
    const cancelTokenSource = axios.CancelToken.source();
    let total = 0;
    let args = {};
    let error = null;
    let filteredBooks = [];
    if (searchQuery) {
        args = {...args, search: searchQuery};
    }
    if (page) {
        args = {...args, page: page, page_size: perPage};
    }
    
    try {
        const response = await axios.get(`${API_BASE_URL}/books`, {
            params: args,
            cancelToken: cancelTokenSource.token,
            headers: {
                'Accept-Language': i18n.language,
            },
        });

        if (response.status === 200 && response.data.success) {
            total = response.data.total;
            filteredBooks = response.data.books.map(book => formatBook(book))
        } else {
            error = "Failed to Fetch Books";
        }
    } catch (e) {
        error = e;
    }
    
    if (!page || page === 0) {
        return {
            books: filteredBooks,
            hasMore: false,
            cancelTokenSource: cancelTokenSource,
            error: error,
        };
    }
    return {
        books: filteredBooks,
        hasMore: total > page * perPage,
        cancelTokenSource: cancelTokenSource,
        error: error,
    };
  };

export const getBookById = async (id) => {
    // Get book with specific Id from fatwa.bo.composdz.com/book/<id>
    let book = await fetch(`${API_BASE_URL}/book/${id}`, {
        headers: {
            'Accept-Language': i18n.language,
          },
        }).then(response => response.json())
        .then(data => {
            if (data) {
                return formatBook(data);
            } else {
                throw new Error("Failed to fetch book with id: " + id);
            }
        });
    return book;
};
export const submitQuestion = async (formData, recaptchaToken) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/question`, {
            "firstname": formData.firstName,
            "lastname": formData.lastName,
            "phone": formData.phone,
            "subject": formData.title,
            "message": formData.question,
            "recaptcha_token": recaptchaToken,
        });

        // Check the response status
        if (response.status === 200) {
            // Format the UUID to refNo
            return { refNo: response.data.uuid };
        } else if (response.status === 400) {
            throw new Error('Bad Request: ' + response.data.message || 'Invalid input.');
        } else {
            // Handle other statuses
            throw new Error('Unexpected error: ' + response.statusText);
        }
    } catch (error) {
        // Return the error message or handle it as needed
        throw new Error('Failed to submit question. Please try again later.');
    }
};

export const bookCall = async (formData, muftiId, recaptchaToken) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/rdv`, {
            "firstname": formData.firstName,
            "lastname": formData.lastName,
            "phone": formData.phone,
            "email": formData.email,
            "mufti_id": muftiId,
            "subject": formData.callSubject,
            "recaptcha_token": recaptchaToken,
        });

        // Check the response status
        if (response.status === 200) {
            return response.data; 
        } else if (response.status === 400) {
            throw new Error('Bad Request: ' + response.data.message || 'Invalid input.');
        } else {
            // Handle other statuses
            throw new Error('Unexpected error: ' + response.statusText);
        }
    } catch (error) {
        // Return the error message or handle it as needed
        throw new Error('Failed to book call. Please try again later.');
    }
};

export const createChat = async (message, pseudo) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/create_chat`, {
            message: message,
            pseudo: pseudo,
            ip_address: await getIpAddress()
        });
        return response.data;
    } catch (error) {
        console.error('Error creating chat channel:', error);
        return null;
    }
};

export const sendMessageToChannel = async (channelUuid, messageContent, pseudo) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/send_chat`, {
            channel_uuid: channelUuid,
            message_content: messageContent,
            pseudo: pseudo,
            ip_address: await getIpAddress()
        });
        return response.data;
    } catch (error) {
        console.error('Error sending message to channel:', error);
        return null;
    }
};

export const getChatHistory = async (channelUuid) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/chats/${channelUuid}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching chat history:', error);
        return null;
    }
};

export const getIpAddress = async () => {
    try {
        const response = await axios.get('https://json.geoiplookup.io');
        return response.data.ip;
    } catch (error) {
        console.error('Error fetching IP address:', error);
        return null;
    }
};
