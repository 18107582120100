import React, { useState } from 'react';
import MuftiList from '../components/MuftiList';
import BookingForm from '../components/BookingForm';
import BookingConfirmation from '../pages/BookingConfirmation';

const VideoCallBooking = () => {
    const [selectedMufti, setSelectedMufti] = useState(null);
    const [bookingDetails, setBookingDetails] = useState(null);

    const handleSelectMufti = (mufti) => {
        setSelectedMufti(mufti);
    };

    const handleConfirmBooking = (details) => {
        setBookingDetails(details);
    };

    return (
        <div>
            {!bookingDetails ? (
                <>
                    {!selectedMufti ? (
                        <MuftiList onSelectMufti={handleSelectMufti} />
                    ) : (
                        <BookingForm mufti={selectedMufti} onConfirm={handleConfirmBooking} />
                    )}
                </>
            ) : (
                <BookingConfirmation bookingDetails={bookingDetails} />
            )}
        </div>
    );
};

export default VideoCallBooking;
